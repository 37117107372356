import store from '@/store';
const cateTree = (options) =>{
    let arr = new Map()
    options.forEach(item => {
        arr.set(item.catalog_id,item)
    })
    localStorage.setItem('cateTree',arr)
    store.commit('setCateTree',arr)
    
}

const cateLabel = (options) =>{
    let arr =  store.state.cateTree || localStorage.getItem('cateTree')
    return arr.get(options)
}

export {
    cateTree,
    cateLabel
}