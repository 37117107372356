import axios from 'axios'
import { ElMessage } from 'element-plus'
var instance = axios.create({
    // baseURL: 'https://zcapi.sdlysm.com',
    baseURL: 'https://zcapi.sdjhdz.cn',
    timeout: 10000,
});

instance.interceptors.request.use(function (config) {
    Object.assign(config.data,{token:localStorage.getItem('token') || ''})
    return config;
  }, function (error) {
    return Promise.reject(error);
  });

// 添加响应拦截器
instance.interceptors.response.use(response => {
    const res = response.data;
    if(res.code === 0){
        return res
    }else{
        if(res.msg == '您未登录或登录已超时 , 请先去登录!' || res.msg == '您的登录已超时 , 请重新登陆!'){
            localStorage.removeItem('token')
            location.href = '#/login'
        }else{
            return res
            // return Promise.reject(res.msg);
        }
    }
},error=>{
    return Promise.reject(error);
});

export default instance;