import { createStore } from 'vuex'

export default createStore({
  state: {
    webInfo:{},
    token:'',
    cateTree:''
  },
  getters: {
  },
  mutations: {
    setWebInfo(state,data){
      state.webInfo = data;
    },
    setToken(state,data){
      state.token = data;
    },
    setCateTree(state,data){
      state.cateTree = data;
    }
  },
  actions: {
  },
  modules: {
  }
})
