import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component:()=>import('../views/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component:()=>import('../views/login.vue')
  },
  {
    path: '/class/:id/:cate_title',
    name: 'class',
    component:()=>import('../views/class.vue')
  },
  {
    path: '/goods',
    name: 'goods',
    component:()=>import('../views/goods.vue')
  },
  {
    path: '/order',
    name: 'order',
    component:()=>import('../views/order.vue')
  },{
    path: '/mine',
    name: 'mine',
    component:()=>import('../views/mine.vue'),
    redirect:'/personl',
    children:[{
      path: 'personl',
      component:()=>import('../views/personl.vue')
    },{
      path: 'mineOrder',
      component:()=>import('../views/mineOrder.vue')
    }]
  },{
    path: '/about',
    name: 'about',
    component:()=>import('../views/about.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
