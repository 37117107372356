import request from '@/utils/request.js';
export function getWebInfo(data = {}) {
    return request({
      url:'/home/index/index',
      method: 'post',
      data
    })
}
// 品牌列表
export function brand_list(data = {}) {
  return request({
    url:'/brand/brand_list',
    method: 'post',
    data
  })
}
// 商品列表
export function goods_list(data = {}) {
  return request({
    url:'/home/Goods/goods_list',
    method: 'post',
    data
  })
}
// 商品详情
export function goods_details(data = {}) {
  return request({
    url:'/home/Goods/goods_details',
    method: 'post',
    data
  })
}
// 下单
export function add_order(data = {}) {
  return request({
    url:'/home/order/add_order',
    method: 'post',
    data
  })
}
// 个人资料
export function personal(data = {}) {
  return request({
    url:'/home/personal/personal',
    method: 'post',
    data
  })
}
// 订单列表
export function order_list(data = {}) {
  return request({
    url:'/home/order/order_list',
    method: 'post',
    data
  })
}
// 关于我们
export function about_us(data = {}) {
  return request({
    url:'/home/index/about_us',
    method: 'post',
    data
  })
}
// 登陆
export function userLogin(data) {
  return request({
    url:'/home/Login/login',
    method: 'post',
    data
  })
}






