<template>
  <router-view/>
</template>
<script setup>
  import {useStore} from 'vuex'
  import { getWebInfo } from '@/api/hkk.js'
  import { cateTree } from '@/utils/tool';
  const store = useStore()
  getWebInfo().then(res=>{
    let response = res.data
    document.title = response.site_name
    if(response.cate.length > 6){
      cateTree(response.cate)
      response.cate = response.cate.slice(0,6)
      response.cate.push({
        cate_title:'关于我们'
      })
    }
    store.commit('setWebInfo',response)
  })
</script>

<style lang="scss">
@import './assets/config.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
