import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import store from './store'


createApp(App).use(store).use(router).use(ElementPlus,{locale:zhCn}).mount('#app')
